<template>
  <div>
    <div class="imgBox" id="mycanvas">
      <!--<img class="haiBaoErWeiMa" src="../../assets/images/1.jpg" alt="" />-->
      <div class="poster bgCode">
        <vue-qr class="QrcodeBg" :text="url" :size="100" :margin="3"> </vue-qr>
      </div>
      <div class="posterText" style="bottom: 5px">
        推荐码：{{ proportionNum }}
      </div>
    </div>
    <div class="newErWeiMa" v-if="saveUrl">
      <img :src="saveUrl" alt="" />
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr';
import html2canvas from 'html2canvas';

export default {
  name: 'EvaluationList',
  components: { VueQr },
  data() {
    let proportionNum = localStorage.getItem('proportionNum');
    return {
      //logo: "https://img1.baidu.com/it/u=1966616150,2146512490&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1656781200&t=6bb14b7a7af4f37d7c6d4143e0150c28",
      logo: '',
      url: `https://v.hxnft.shop/#/login?code=${proportionNum}`,
      proportionNum: '',
      saveUrl: '',
    };
  },
  created() {
    this.proportionNum = localStorage.getItem('proportionNum');

    //二维码测试环境
    //this.url = `http://ibox.azging.com/#/register?id=${this.proportionNum}`;
    //二维码生产环境
    // this.url = `http://c.cdsaoshu.com//#/register?id=${this.proportionNum}`;
  }, // 生命周期-创建完成
  mounted() {
    setTimeout(() => {
      this.saveImg();
    }, 1000);
  }, // 生命周期-创建之前
  methods: {
    saveImg() {
      // 先获取你要转换为img的dom节点
      var node = document.getElementById('mycanvas'); // 传入的id名称
      var width = node.offsetWidth; // dom宽
      var height = node.offsetHeight; // dom高
      var scale = 2; // 放大倍数 这个相当于清晰度 调大一点更清晰一点
      html2canvas(node, {
        width: width,
        heigth: height,
        backgroundColor: '#ffffff', // 背景颜色 为null是透明
        dpi: window.devicePixelRatio * 2, // 按屏幕像素比增加像素
        scale: scale,
        X: 0,
        Y: 0,
        scrollX: -3, // 如果左边多个白边 设置该偏移-3 或者更多
        scrollY: 0,
        useCORS: true, // 是否使用CORS从服务器加载图像 !!!
        allowTaint: true, // 是否允许跨域图像污染画布  !!!
      }).then((canvas) => {
        this.saveUrl = canvas.toDataURL('image/png');
        // let aLink = document.createElement('a')
        // this.blob = this.base64ToBlob(saveUrl)
        // let evt = document.createEvent('HTMLEvents')
        // evt.initEvent('click', true, true)
        // aLink.download = '二维码.jpg'
        // aLink.href = URL.createObjectURL(blob)
        // aLink.click()
        //	        var Url = canvas.toDataURL(); // 这里上面不设值cors会报错
        //	        var a = document.createElement('a'); // 创建一个a标签 用来下载
        //	        a.download = '海报二维码'; // 设置下载的图片名称
        //	        var event = new MouseEvent('click'); // 增加一个点击事件
        //	        a.href = Url; // 此处的url为base64格式的图片资源
        //	        a.dispatchEvent(event); // 触发a的单击事件 即可完成下载
      });
    },
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
  },
};
</script>

<style scoped>
/* img {width: 100%;
	height: 100%;
	display: block;
} */
.imgBox {
  position: relative;
  width: 300px;
  height: 400px;
  /*background-color: rgba(0,0,0,.7);*/
}
.bgCode {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/1.jpg');
  background-size: 100%;
}
.QrcodeBg {
  position: absolute;
  top: 270px;
  left: 50%;
  transform: translateX(-50%);
}
.newErWeiMa {
  position: fixed;
  width: 300px;
  height: 400px;
  left: 0;
  top: 0;
  z-index: 9999;
}
.newErWeiMa > img {
  width: 300px;
  height: 400px;
  display: block;
}
.poster {
  /*position: absolute;
  left: 110px;
  bottom: 20px;*/
}
.posterText {
  font-size: 8px;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}
</style>
