<template>
  <div>
    <!-- 遮罩层组件 -->

    <van-popup
      class="erwei"
      :close-on-click-overlay="true"
      v-model="show"
      style="overflow: hidden"
    >
      <Poster />
    </van-popup>
  </div>
</template>
<script>
import Poster from "./index";
export default {
  components: { Poster },
  data() {
    return {
      show: true,
    };
  },
};
</script>
<style lang="less" scoped>
</style>